import Spline from '@splinetool/react-spline';

export default function App() {
  return (
    <>
      <Spline scene="https://prod.spline.design/owhCduEnKZ7U3MFw/scene.splinecode" />
      <p style={{position: "fixed", top: "10px", left: "10px", zIndex: 10}}>Created By: Akshay Rana Using spine tool</p>
    </>
  );
}
